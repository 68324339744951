<template>
  <v-container>
    <v-row>
      <v-col :cols="cols">
        <p>Distance:</p>
        <number-input v-model="filter.distance" :min="50" :max="45000" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Economic Class Passengers:</p>
        <number-input v-model="filter.economicClass" :min="0" :max="100000" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>First Class Passengers:</p>
        <number-input v-model="filter.firstClass" :min="0" :max="100000" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Business Class Passengers:</p>
        <number-input v-model="filter.businessClass" :min="0" :max="100000" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Over Percentage Passengers:</p>
        <number-input v-model="filter.overPercentage" :min="0" :max="100" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Category:</p>
        <number-input v-model="filter.categoryAirport" :min="1" :max="10" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Max Number of Aircraft:</p>
        <number-input v-model="filter.maxDepth" :min="1" :max="3" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Exclude Airplanes:</p>
        <v-autocomplete v-model="filter.excludeAirplanes" :items="airplanes" chips deletable-chips item-value="id_airplane" :item-text="airplaneName" multiple clearable hide-details dense></v-autocomplete>
      </v-col>
      <v-col :cols="cols">
        <p>Include Airplanes:</p>
        <v-autocomplete v-model="filter.includeAirplanes" :items="airplanes" chips deletable-chips item-value="id_airplane" :item-text="airplaneName" multiple clearable hide-details dense></v-autocomplete>
      </v-col>
      <v-col :cols="cols">
        <p>Max Price Gold Coins per Airplane:</p>
        <number-input v-model="filter.maxGold" :min="7500000" :max="408000000" :step="100000" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols">
        <p>Max Price AM Coins per Airplane:</p>
        <number-input v-model="filter.maxAMCoins" :min="0" :max="20" :step="5" controls center size="small"></number-input>
      </v-col>
      <v-col :cols="cols" class="align-self-end">
        <v-row class="ma-0 pa-0">
          <v-col cols="6" class="ma-0 pa-0">
            <v-btn block color="blue" text @click="copyFilters">Copy Data</v-btn>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-btn block color="red" text @click="resetFilter">Reset Filters</v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="6" class="ma-0 pa-0">
            <v-btn block color="green" text @click="findOptions">Find Options</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :page="page"
          :headers="headers"
          :items="options"
          :loading="loading"
          multi-sort
        >
          <template v-slot:item.total_price_gold="{ item }">
            {{item.total_price_gold}}
          </template>
          <template v-slot:item.total_price_am_coins="{ item }">
            {{item.total_price_am_coins}}
          </template>
          <template v-slot:item.remainingPassengers="{ item }">
            <div class="d-inline-block pa-2"><b>M:</b> {{item.remainingPassengers[0]}}</div>
            <div class="d-inline-block pa-2"><b>T:</b> {{item.remainingPassengers[1]}}</div>
            <div class="d-inline-block pa-2"><b>W:</b> {{item.remainingPassengers[2]}}</div>
            <div class="d-inline-block pa-2"><b>R:</b> {{item.remainingPassengers[3]}}</div>
            <div class="d-inline-block pa-2"><b>F:</b> {{item.remainingPassengers[4]}}</div>
            <div class="d-inline-block pa-2"><b>S:</b> {{item.remainingPassengers[5]}}</div>
            <div class="d-inline-block pa-2"><b>U:</b> {{item.remainingPassengers[6]}}</div>
          </template>
          <template v-slot:item.num_airplanes="{ item }">
            {{item.airplanes.length}}
          </template>
          <template v-slot:item.airplanes="{ item }">
            <v-row v-for="(a,idx) in item.airplanes" :key="'a'+idx" class="ma-0 pa-0">
              <v-col cols="3" class="ma-0 pa-0">
                <b>{{a.scope}}</b> {{a.company}} {{a.model}}
                <br>
                <b>C:</b>{{a.category}} <b>Fuel:</b>{{a.fuel_consumption}}  <b>Wear:</b>{{a.wear_coefficient}}
                <br>
                <b>Gold:</b>{{a.price_gold}}
                <br>
                <b>AM Coins:</b>{{a.price_am_coins}} 
              </v-col>
              <v-col cols="3" class="ma-0 pa-0 align-self-center">
                <v-progress-linear
                  :value="a.occupation"
                  height="10"
                >
                  <strong>{{ Math.ceil(a.occupation) }}%</strong>
                </v-progress-linear>
                <br>
                <b>RTT:</b>{{a.rtt}}
              </v-col>
              <v-col cols="3">
                <div class="d-inline-block pa-2"><b>Eco:</b> {{a.distribution.economicClassPassengers}}</div>
                <div class="d-inline-block pa-2"><b>First:</b> {{a.distribution.firstClassPassengers}}</div>
                <div class="d-inline-block pa-2"><b>Bus:</b> {{a.distribution.businessClassPassengers}}</div>
                <br>
                <v-btn text small color="blue" @click="doCopyAirplane(a)">Copy</v-btn>
              </v-col>
              <v-col cols="3">
                <div class="d-inline-block pa-2"><b>M:</b> {{a.flights[0]}}</div>
                <div class="d-inline-block pa-2"><b>T:</b> {{a.flights[1]}}</div>
                <div class="d-inline-block pa-2"><b>W:</b> {{a.flights[2]}}</div>
                <div class="d-inline-block pa-2"><b>R:</b> {{a.flights[3]}}</div>
                <div class="d-inline-block pa-2"><b>F:</b> {{a.flights[4]}}</div>
                <div class="d-inline-block pa-2"><b>S:</b> {{a.flights[5]}}</div>
                <div class="d-inline-block pa-2"><b>U:</b> {{a.flights[6]}}</div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="copyAlert"
      top
      color="green accent-4 white--text"
      elevation="10"
      timeout="3000"
    >
      Copied!!!
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'
import Clipboard from '@codeeshop/vue-clipboard'

export default {
  components:{
    Clipboard
  },
  data: () => ({
    filter:{
      distance: 50,
      economicClass: 0,
      firstClass: 0,
      businessClass: 0,
      overPercentage: 5,
      simulationMode: 1,
      categoryAirport: 10,
      maxDepth: 3,
      excludeAirplanes: [],
      includeAirplanes: [],
      maxGold: 408000000,
      maxAMCoins: 20
    },
    airplanes:[],
    options:[],
    loading: false,
    page: 1,
    headers:[
      {
        text: '# Airplanes',
        value: 'num_airplanes',
        width: '7%',
        align:'center',
      },
      {
        text: 'Total Price Gold',
        value: 'total_price_gold',
        width: '10%',
        align:'center',
      },
      {
        text: 'Total Price AM Coins',
        value: 'total_price_am_coins',
        width: '10%',
        align:'center',
      },
      {
        text: 'Remaining Passengers',
        value: 'remainingPassengers',
        width: '15%',
        align:'center',
      },
      {
        text: 'Airplanes',
        value: 'airplanes',
        align:'center',
      },
    ],
    copyAlert:false,
  }),
  computed: {
    cols(){
      if(this.$vuetify.breakpoint.xs) return 12; 
      else if(this.$vuetify.breakpoint.sm) return 6;
      else if(this.$vuetify.breakpoint.md) return 4;
      else return 3;
    }
  },
  created(){
  },
  watch: {
  },
  methods: {
    airplaneName: airplane => `${airplane.company} - ${airplane.model}`,
    resetFilter(){
      this.filter.distance = 50;
      this.filter.economicClass = 0;
      this.filter.firstClass = 0;
      this.filter.businessClass = 0;
      this.filter.overPercentage = 5;
      this.filter.simulationMode = 1;
      this.filter.maxDepth = 3;
      this.filter.categoryAirport = 10;
      this.filter.excludeAirplanes = [];
      this.filter.includeAirplanes = [];
      this.filter.maxGold = 408000000;
      this.filter.maxAMCoins = 20;
    },
    findOptions(){
      this.loading = true;
      this.page = 1;
      this.options = [];
      var obj = {
        Distance: this.filter.distance,
        EconomicClassPassengers: this.filter.economicClass,
        FirstClassPassengers: this.filter.firstClass,
        BusinessClassPassengers: this.filter.businessClass,
        OverPercentage: this.filter.overPercentage,
        SimuationMode: this.filter.simulationMode,
        CategoryAirport: this.filter.categoryAirport,
        MaxDepth: this.filter.maxDepth,
        ExcludeAirplanes: this.filter.excludeAirplanes,
        IncludeAirplanes: this.filter.includeAirplanes,
        MaxGold: this.filter.maxGold,
        MaxAMCoins: this.filter.maxAMCoins,
      }

      axios
      .post("/Simulation",obj)
      .then(response => {
        this.options = response.data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    },
    getAirplanes(){
      axios
      .get("/Simulation/Airplanes")
      .then(response => {
        this.airplanes = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    doCopyAirplane(a) {
      const sum = a.flights.reduce((a, b) => a + b, 0);
      const avg = (sum / a.flights.length) || 0;
      var text = `${a.scope}\t${a.company} ${a.model}\t${a.distribution.economicClassPassengers}\t${a.distribution.firstClassPassengers}\t${a.distribution.businessClassPassengers}\t?\t${a.price_gold}\t${a.rtt}\t${avg}`;

      this.$copyText(text).then((e) => {
        this.copyAlert = false;
        this.copyAlert = true;
      }, function (e) {

      })
    },
    copyFilters(){
      var text = `${this.filter.economicClass}\t${this.filter.firstClass}\t${this.filter.businessClass}`;
      this.$copyText(text).then((e) => {
        this.copyAlert = false;
        this.copyAlert = true;
      }, function (e) {

      })
    }
  },
  mounted() {
    this.getAirplanes();
  }
}
</script>