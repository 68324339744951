// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import router from '@/router'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/Users/Login', { username: userData.username, password: userData.password })
        .then(response => {
          if(typeof response.data.token !== 'undefined'){
            const token = response.data.token

            // obtain claims
            var ca = token
            var base64Url = ca.split('.')[1];
            var payload = JSON.parse(window.atob(base64Url));
            const user = payload.Nombre;
            
            // storing jwt in localStorage. https cookie is safer place to store
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            
            // mutation to change state properties to the values passed along
            commit('auth_success', { token, user })
            resolve('auth_success')
          }
          if(typeof response.data.msg !== 'undefined' && response.data.msg == 'DF'){
            commit('double_factor_required')
            resolve('double_factor_required')
          }
        })
        .catch(err => {
          console.log('login error')
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  refreshtoken ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/Users/Refresh')
      .then(response => {
        const token = response.data.token
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        commit('auth_success', { token })
        resolve()
      })
      .catch(error => {
        console.log('refresh token error')
        console.log(error)
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']  
        router.push("/")
        reject()
      })
    })
  }
}
