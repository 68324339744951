<template>
  <v-row
    class="mb-6 ma-0 pa-0"
    no-gutters
  >
    <v-snackbar
      v-model="snackbar"
      top
      color="red"
    >
      Nombre de Usuario y/o Contraseña Incorrectos
    </v-snackbar>
    <v-col v-if="show" :lg="cols[0]" :md="6" :sm="cols[0]">
      <v-img src="https://source.unsplash.com/600x960/?airline,airplane" class="img-responsive" :max-height="maxHeight"></v-img>
    </v-col>
    <v-col :lg="cols[1]" :md="6" :sm="cols[1]" class="align-self-center">
      <v-row class="justify-center">
        <v-col class="text-center" cols="8" md="8" sm="8">
          <v-img src="/img/logotipo-600x100.png" max-height="75px" max-width="450px"/>
        </v-col>
        <v-col cols="8" md="8" sm="8">
          <v-text-field
            v-model="username"
            :rules="[rules.required, rules.min6]"
            label="Nombre de Usuario"
            hint="Al menos 6 caracteres"
            counter
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="8" sm="8">
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min8]"
            :type="show1 ? 'text' : 'password'"
            label="Contraseña"
            hint="Al menos 8 caracteres"
            counter
            @click:append="show1 = !show1"
            @keydown.enter="login"
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="8" sm="8">
          <v-spacer></v-spacer>
          <v-btn
            dark
            block
            align-center
            justify-center
            color="primary"
            @click="login"
          >
            Iniciar Sesion
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      username:'',
      password: '',
      rules: {
        required: value => !!value || 'Requerido.',
        min6: v => v.length >= 6 || 'Minimo 8 caracteres',
        min8: v => v.length >= 8 || 'Minimo 8 caracteres',
      },
      snackbar: false,
    }
  },
  methods: {
    login: function () {
      let username = this.username
      let password = this.password
      this.$store.dispatch('login', { username, password })
        .then(response => {
          if(response == 'auth_success'){
            this.$router.push('/dashboard')
          }
        })
        .catch(err => {
          console.log(err)
          this.snackbar = true
        })
    },
  },
  computed: {
    show(){
      return (!this.$vuetify.breakpoint.xs);
    },
    maxHeight(){
      if(this.$vuetify.breakpoint.xs){
        return "0px";
      }
      else if(this.$vuetify.breakpoint.sm){
        return "250px";
      }
      else return "100vh";
    },
    cols () {
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
       return [12,12]; 
      }
      else return [6,6]
    },
  },
}
</script>

<style scoped>
  .img-responsive{
    /*height:100vh !important;*/
    width: auto !important;
  }
</style>